import React, {Fragment} from "react";


function Safety() {


  return (
    <Fragment>
         <div className="main-content bg-white ">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left pe-0">
                            <div className="row">
  <h2>Child Safety Policy for GeetSuhane</h2>   
  <p>
  1. Introduction
  <br />
At GeetSuhane, we are committed to creating a safe and secure environment for children using our platform. We strictly adhere to child protection laws and Google Play’s Child Safety Standards Policy. This page outlines our commitment to preventing child sexual abuse and exploitation (CSAE) and ensuring a safe experience for all users.
</p>
<p>
2. Protection Against Child Exploitation
<br />

We have a zero-tolerance policy against child sexual abuse and exploitation. Our platform enforces the following measures to ensure child safety:
<br />
Prohibited Content: Any content involving child exploitation, abuse, or inappropriate interactions is strictly forbidden.
<br />

Moderation & Reporting: We actively monitor content and provide reporting tools to allow users to flag violations.
<br />

Law Enforcement Collaboration: We cooperate with legal authorities, including the National Center for Missing & Exploited Children (NCMEC), to report and take action against any suspected child exploitation.
</p>
<p>
3. Age Restrictions & Parental Control
<br />

To ensure the safety of minors:
<br />

Our app is designed for users aged 13 and above.
<br />

If any child under the age of 13 (or the age required by local laws) accesses our app, we require verifiable parental consent.
<br />

Parents can request the removal of their child’s data at any time.
</p>
<p>
4. Data Privacy & Protection for Children
<br />

We comply with COPPA (Children’s Online Privacy Protection Act) and GDPR-K (General Data Protection Regulation for Kids):
<br />

We do not collect, share, or sell any personal information from children without parental consent.
<br />

We use strict security measures to protect user data from unauthorized access.
<br />

For more details, please refer to our Privacy Policy.
</p>
<p>
5. Reporting & Contact Information
<br />

If you come across any inappropriate content or suspect child exploitation, please report it immediately via:
<br />

Report Button: Available within the app.
<br />

Email: support@geetsuhane.com
<br />

Law Enforcement Contact: If you believe a child is in danger, contact local authorities or report to NCMEC CyberTipline.
</p>
<p>
6. Compliance & Updates
<br />

We continuously update our safety policies to comply with legal standards and best practices. We encourage parents and guardians to stay informed and engage with their children’s digital activities.
</p>

      </div>
      </div>
      </div>
      </div>
    </Fragment>
  );
}

export default Safety;
