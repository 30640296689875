import React, { useState, useEffect,useContext } from "react";
import profileService from '../services/profile.service';
import alertService from "../services/sweetAlert";
import { AuthContext } from '../context/authContext';

const RedeemPage = () => {
    const { currentUser } = useContext(AuthContext);
  const [plans, setPlans] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [gscBalance, setGscBalance] = useState(0); // Example balance
  const [airtelNumber, setAirtelNumber] = useState("");
  const [message, setMessage] = useState("");
  const cdn = 'https://files.geetsuhane.com/';
  // Mock data for Airtel plans
  useEffect(() => {
    const fetchPlans = async () => {
        const dummyPlans = [
            { id: 1, name: "Airtel Unlimited Pack", price: 399, validity: "28 days", description: "1GB/day, Unlimited Calls" },
            { id: 2, name: "Airtel Unlimited Pack", price: 449, validity: "28 days", description: "1.5GB/day, Unlimited Calls" },
            { id: 3, name: "Jio Unlimited Pack", price: 449, validity: "28 days", description: "2GB/day, Unlimited Calls" },
            { id: 4, name: "Jio Unlimited Pack", price: 298, validity: "14 days", description: "2GB/day, Unlimited Calls" },
            { id: 5, name: "Jio Unlimited Pack", price: 499, validity: "28 days", description: "2.5GB/day, Unlimited Calls" },
          ];
      setPlans(dummyPlans);
    };

    const fetchProducts = async () => {
      const dummyProducts = [
        { id: 1, name: "Rose-Gold Magnet Watch With Rosegold Bracelet", price: 500, description: "Premium Analogue Women's Rose-Gold Magnet Watch With Rosegold Bracelet", image: cdn+'redeem/watch-w.jpg' },
        { id: 2, name: "Black Analog Digital Sport Watches", price: 1000, description: "V2A Cammando Midnight Black Analog Digital Sport Watches", image: cdn+'redeem/watch.jpg' },
        { id: 3, name: "EOX E2 Electric Scooter for Adults", price: 55000, description: "Non RTO EV Bike with 60-80Km Range, Waterproof Motor, 32AH 60V", image: cdn+'redeem/scooter.jpg' },
        { id: 4, name: "Hero Xpulse 200 4V (ABS Disc) Bike", price: 150000, description: "Hero Xpulse 200 4V (ABS Disc) Bike Techno Blue Matt Black Booking", image: cdn+'redeem/bike.jpg' },
        { id: 5, name: "iPhone 16 Pro Max 1 TB: 5G Mobile Phone", price: 180100, description: "iPhone 16 Pro Max 1 TB: 5G Mobile Phone with Camera Control, 4K 120 fps Dolby Vision", image: cdn+'redeem/iphone16prod.jpg' }
      ];
      setProducts(dummyProducts);
    };

    fetchPlans();
    fetchProducts();
    getCash();
  }, [currentUser]);

  const getCash = async() => {
      var cashval = await profileService.getResult({
          qry: `CALL sp_getIncome('${currentUser?.id}');`
      });
      console.log(cashval);
     setGscBalance(cashval?.[0][0]?.rupee);
    
  };

  const handleRedeem = async () => {
    if(gscBalance<500) {
        alertService.info(
          'Redeem',
          'You need to have balance minimum GSC 500, terms and consitions apply!'
        ) 
        return;
      } 
    if (!selectedItem) {
      setMessage("Please select a plan or product.");
      return;
    }

    if (selectedItem.type === "plan" && !airtelNumber) {
      setMessage("Please enter your phone number.");
      return;
    }

    if (gscBalance < selectedItem.price*2) {
      setMessage("Insufficient GSC balance.");
      return;
    }

    try {
        const res = await profileService.getResult({
          qry: `CALL sp_redeem('${currentUser?.id}',${selectedItem?.id},'${selectedItem?.name}','${selectedItem?.type}',${selectedItem?.price},'${airtelNumber}');`
        }).then(r=> {
            console.log(r);
            if(r?.[0][0]?.res!= 'success') {
                alertService.error(
                'Redeem',
                r?.[0][0]?.res
                ) 
                return;
            } else {
                alertService.success(
                'Redeem',
                r?.[0][0]?.res
                )
            }
        });     
      } catch (error) {
        console.error("Error fetching redeem:", error);
      }

    const redemptionMessage =
      selectedItem.type === "plan"
        ? `Recharge successful! ${selectedItem.name} worth ₹${selectedItem.price} redeemed for ${airtelNumber}`
        : `Purchase successful! ${selectedItem.name} worth ₹${selectedItem.price} redeemed.`;

    setMessage(redemptionMessage);
    setGscBalance(gscBalance - selectedItem.price);

    // Add API calls for Airtel recharge or product purchase here
  };

  return (
    <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left pe-0">

                <div style={{ padding: "20px", maxWidth: "600px", margin: "auto", fontFamily: "Arial, sans-serif" }}>
                <h2 className="bg-mini-gradiant text-white p-1 text-center strong  ">Redeem GSC</h2>              
                <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                    {products.map((product) => (
                    <div
                        key={product.id}
                        onClick={() => setSelectedItem({ ...product, type: "product" })}
                        style={{
                        border: selectedItem?.id === product.id && selectedItem.type === "product" ? "2px solid #d32f2f" : "1px solid #ccc",
                        padding: "15px",
                        borderRadius: "5px",
                        cursor: "pointer",
                        backgroundColor: selectedItem?.id === product.id && selectedItem.type === "product" ? "#ffeaea" : "#fff",
                        }}
                    >
                        <img src={product.image} alt={product.name} style={{ width: "200px", height: "200px", objectFit: "cover", borderRadius: "5px", marginRight: "10px", alignContent: "center" }} />
                        <h4 className="p-3">{product.name}</h4>
                        <p style={{ margin: "0", fontSize: "14px" }}>Price: ₹{product.price} / GSC: {product.price}</p>
                        <p style={{ margin: "0", fontSize: "12px", color: "#666" }}>{product.description}</p>
                    </div>
                    ))}
                </div>

            
                <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                    {plans.map((plan) => (
                    <div
                        key={plan.id}
                        onClick={() => setSelectedItem({ ...plan, type: "plan" })}
                        style={{
                        border: selectedItem?.id === plan.id && selectedItem.type === "plan" ? "2px solid #d32f2f" : "1px solid #ccc",
                        padding: "15px",
                        borderRadius: "5px",
                        cursor: "pointer",
                        backgroundColor: selectedItem?.id === plan.id && selectedItem.type === "plan" ? "#ffeaea" : "#fff",
                        }}
                    >
                        <h4 style={{ margin: "0 0 5px 0" }}>{plan.name}</h4>
                        <p style={{ margin: "0", fontSize: "14px" }}>Price: ₹{plan.price} / GSC: {plan.price}</p>
                        <p style={{ margin: "0", fontSize: "14px" }}>Validity: {plan.validity}</p>
                        <p style={{ margin: "0", fontSize: "12px", color: "#666" }}>{plan.description}</p>
                    </div>
                    ))}
                </div>
                <div style={{ marginBottom: "20px" }}>
                  
                  <input
                  type="text"
                  id="airtelNumber"
                  placeholder="Enter your Phone number"
                  value={airtelNumber}
                  onChange={(e) => setAirtelNumber(e.target.value)}
                  style={{ width: "100%", padding: "10px", boxSizing: "border-box", borderRadius: "5px", border: "1px solid #ccc" }}
                  />
              </div>

                <button
                    onClick={handleRedeem}
                    style={{
                    marginTop: "20px",
                    backgroundColor: "#d32f2f",
                    color: "#fff",
                    border: "none",
                    padding: "10px 15px",
                    cursor: "pointer",
                    width: "100%",
                    borderRadius: "5px",
                    fontSize: "16px",
                    }}
                >
                    Redeem Now
                </button>

                {message && <p style={{ marginTop: "10px", color: message.includes("successful") ? "green" : "red" }}>{message}</p>}
                <p style={{ marginTop: "20px", fontWeight: "bold" }}>Current GSC Balance: {gscBalance}</p>
                </div>
            </div>
        </div>
    </div>
  );
};

export default RedeemPage;
